@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://example.com/MaterialIcons-Regular.eot);
  /* For IE6-8 */
  src: local('Material Icons'), local('MaterialIcons-Regular'), url(https://example.com/MaterialIcons-Regular.woff2) format('woff2'), url(https://example.com/MaterialIcons-Regular.woff) format('woff'), url(https://example.com/MaterialIcons-Regular.ttf) format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

body {
  background-color: #8080800f !important;
  color: #002b6f !important;
}

header {
  height: 50px;
  background-color: #fff;
}

.header_container {
  display: flex;
  justify-content: space-between;
}
.logo{
  height: 45px;
}
.logo-oney {
  height: 55px;
}

.container-block {
  width: 90%;
  max-width: 1000px;
  margin: 0 auto;
}

.simulation_title {
  font-size: 24px;
  color: #002b6f;
  text-transform: uppercase;
  text-align: center;
  margin-top: 22px;
  margin-bottom: 40px;
}

.actions_simulation {
  margin-bottom: 40px;
}

.condition_montant {
  font-size: 14px;
  font-weight: 700;
  color: #002b6f;
}

.option_financement {
  background-color: #fff;
  border-radius: 2px;
  -webkit-box-shadow: 0px 2px 10px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 10px -3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 10px -3px rgba(0, 0, 0, 0.75);
  padding: 15px 0 !important;
}

.titre_financement {
  text-transform: uppercase;
  color: #002b6f;
  font-size: 16px;
  font-weight: 700;
  border-bottom: 1px solid #ccc;
  padding-left: 15px;
  padding-bottom: 10px;
  text-align: center;
}

.conditions_carte {
  font-size: 14px;
  color: #002b6f;
  text-transform: uppercase;
  padding-left: 15px;
}

.prix_annuel {
  text-transform: lowercase;
}

.separate_balisage {
  border-left: 1px solid #ccc;
  color: #969696;
}

.avantages_carte {
  font-size: 14px;
  color: #002b6f;
  font-weight: 700;
}

.carte-norauto {
  margin: 0 auto 0 auto;
  padding: 0;
  display: block;
  margin-bottom: 10px;
  width: 250px;
  margin-top: 20px;
}


.ecomobilistes {
  margin: 0 auto 0 auto;
  display: block;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 180px;
}

.ecomobilistes {
  margin: 0 auto 0 auto;
  padding: 0;
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 180px;
}

.ecomobilistes {
  margin: 0 auto 0 auto;
  padding: 0;
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 180px;
}

/* class génériques */

.p-0 {
  padding: 0 !important;
}

.black_bold {
  color: #000;
  font-weight: 700;
}

#app_impression_imprimer {
  background-color: #fcb731;
  height: 32px;
  width: 255px;
  color: #002b6f;
  border: none;
  text-transform: uppercase;
  font-size: 16px;
  border-radius: 2px;
  -webkit-box-shadow: 0px 2px 10px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 10px -3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 10px -3px rgba(0, 0, 0, 0.75);
  position: relative;
  padding-left: 25px;
}

.simulation_link {
  margin-top: 6px;
}

.relancer-simulation {
  background-color: #fcb731;
  color: #002b6f;
  border: none;
  text-transform: uppercase;
  font-size: 16px;
  border-radius: 2px;
  -webkit-box-shadow: 0px 2px 10px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 10px -3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 10px -3px rgba(0, 0, 0, 0.75);
  position: relative;
  padding: 8px 11px;
}

.relancer-simulation:hover {
  text-decoration: none;
  color: #002b6f;
}

.montant_selection form {
  display: flex;
}

.montant_selection label {
  margin-bottom: 0;
}

.label_montant {
  font-size: 16px;
  margin-top: 5px;
}

.conditions_montant {
  font-size: 14px;
  font-weight: 700;
  display: inline-block;
}

.actions_simulation {
  max-height: 50px;
}

/* encadrés de simulation */

.bloc_choix_simulation {
  display: flex;
}

.encadres_simulation {
  width: 31.6%;
  flex-direction: column;
}

.gratuits, .payants, .facilypay, .promo, .rond {
  border-radius: 2px;
  -webkit-box-shadow: 0px 2px 10px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 10px -3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 10px -3px rgba(0, 0, 0, 0.75);
  font-size: 14px;
  margin-right: 20px;
  background-color: #fff;
}

.gratuits label, .payants label, .facilypay label, .promo label {
  text-transform: lowercase;
  font-size: 16px;
  font-weight: 700;
  margin-left: 5px;
}

.content label {
  cursor: pointer;
  margin-top: 5px;
}

/* .facilypay label {
  font-size: 16px;
  text-align: center;
} */

#app_init_simuler, #app_impression_simuler {
  background-color: #fcb731;
  min-height: 32px;
  width: auto;
  color: #002b6f;
  border: none;
  text-transform: uppercase;
  font-size: 16px;
  border-radius: 2px;
  -webkit-box-shadow: 0px 2px 10px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 10px -3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 10px -3px rgba(0, 0, 0, 0.75);
  position: relative;
  margin-bottom: 20px;
}

.gratuits p, .payants p, .facilypay p, .promo p, .rond p {
  color: #616161 !important;
  padding: 0px 10px;
  margin-bottom: 4px;
}

label.montant_simulation {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin-right: 15px;
  margin-bottom: 0;
}

.label_simu {
  margin-top: 5px;
}

.currency_simulation {
  margin-left: 8px;
  font-size: 16px;
  font-weight: 700;
}

.conditions_simulation {
  font-size: 14px;
  font-weight: 700;
  display: inline-block;
}

input#app_impression_montant, input#app_init_montant {
  height: 31px;
  border-radius: 3px;
  border: 1px solid #969696;
  text-align: right;
  padding-right: 10px;
  font-size: 16px;
  color: #616161;
  margin-bottom: 53px;
}

#app_impression_montant::after {
  content: "€"; color: red;
}

.details_simu {
  font-size: 16px;
  margin-bottom: 25px;
  margin-top: 15px;
  padding-left: 15px;
}

.recap_simu {
  font-size: 14px;
  padding-left: 15px;
  margin-bottom: 10px;
}

/* icone print */

.print-icone::before {
  content: '\E555';
  font-family: 'Material Icons';
  margin-right: 10px;
  position: absolute;
  left: 17px;
  top: 2px;
  font-size: 20px;
}

/* style checkbox  */

input[type=checkbox] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  margin-top: 0 !important;
  cursor: pointer;
  outline: none !important;
}

input[type=checkbox] {
  height: 15px;
  width: 15px;
  background: #fff;
  border: 2px solid #002b6f;
}

input[type="checkbox"]:checked {
  background: none;
  margin: 0px;
  position: relative;
}

input[type="checkbox"]:checked:before {
  font-family: FontAwesome;
  content: '\f00c';
  display: block;
  color: #002b6f;
  font-size: 11px;
  position: absolute;
}

/* Test class active */

/* .active {
  background-color: red;
} */

.onglet span {
  vertical-align: middle;
  display: table-cell;
  height: 50px;
  margin: 0 auto;
  width: 50;
}

.payants .onglet, .gratuits .onglet, .promo .onglet, .rond .onglet {
  background-color: #002b6f;
  color: #767676;
  font-size: 16px;
  font-weight: 700;
  min-height: 50px;
  vertical-align: middle;
  cursor: pointer;
  width: 50%;
  float: left;
  text-align: center;
  position: relative;
  border-bottom: 3px solid transparent;
  padding: 3px 3px 0 3px;
}


.facilypay .onglet {
  background-color: #fff;
  color: #767676;
  border-bottom: 1px solid #ccc;
  padding: 1px 3px 0 3px;
  font-size: 16px;
  font-weight: 700;
  min-height: 50px;
  vertical-align: middle;
  cursor: pointer;
  width: 50%;
  float: left;
  text-align: center;
}

.payants .onglet.active, .gratuits .onglet.active, .promo .onglet.active, .rond .onglet.active {
  border-color: #fcb731;
  color: #fcb731;
}

.facilypay .onglet.active {
  border-color:  #002b6f;
  color: #002b6f;
}

.content {
  margin-top: 62px;
  padding-left: 20px;
}

.flashbag_erreur {
  color: red;
  text-align: center;
  margin-bottom: 15px;
}

/* Responsive */

@media screen and (max-width: 860px) {
  input#app_init_montant {
    width: 90%;
    margin-top: 20px;
    height: 40px;
    margin-bottom: 20px;
  }
  .simulation_title {
    font-size: 26px;
    margin-bottom: 20px;
  }
  .montant_selection form {
    display: block;
  }
  .conditions_montant {
    font-size: 15px;
  }
  .currency_bloc {
    text-align: center;
  }
  .label_montant {
    font-size: 18px;
    width: 100%;
    text-align: center;
  }
  .currency_simulation {
    font-size: 25px;
  }
  .button_bloc {
    text-align: center;
  }
  #app_init_simuler {
    margin-left: 0px;
    width: 100%;
  }
  .option_financement {
    padding-top: 0 !important;
  }
  .titre_financement {
    text-align: center;
    padding: 10px;
  }
}

/* Responsive */

@media screen and (max-width: 860px) {
  input#app_init_montant {
    width: 90%;
    margin-top: 20px;
    height: 40px;
    margin-bottom: 20px;
  }
  .simulation_title {
    font-size: 26px;
    margin-bottom: 20px;
  }
  .montant_selection form {
    display: block;
  }
  .conditions_montant {
    font-size: 15px;
  }
  .currency_bloc {
    text-align: center;
  }
  .label_montant {
    font-size: 18px;
    width: 100%;
    text-align: center;
  }
  .currency_simulation {
    font-size: 25px;
  }
  .button_bloc {
    text-align: center;
  }
  #app_init_simuler {
    margin-left: 0px;
    width: 100%;
  }
  .option_financement {
    padding-top: 0 !important;
  }
  .titre_financement {
    text-align: center;
    padding: 10px;
  }
}


@media screen and (max-width: 991px) {
  .gratuits, .payants, .facilypay, .rond {
    width: 100%;
    margin-bottom: 35px;
  }
  .bloc_choix_simulation {
    flex-wrap: wrap;
  }

  /*.carte-norauto{*/
  /*  margin-left: 0;*/
  /*  margin-right: 0;*/
  /*}*/
}

/* Responsive */

@media screen and (max-width: 900px) {
  input#app_init_montant {
    width: 90%;
    margin-top: 20px;
    height: 40px;
    margin-bottom: 20px;
  }
  .simulation_title {
    font-size: 26px;
    margin-bottom: 20px;
  }
  .montant_selection form {
    display: block;
  }
  .conditions_montant {
    font-size: 15px;
  }
  .currency_bloc {
    text-align: center;
  }
  .label_montant {
    font-size: 18px;
    width: 100%;
    text-align: center;
  }
  .currency_simulation {
    font-size: 25px;
  }
  .button_bloc {
    text-align: center;
  }
  #app_init_simuler {
    margin-left: 0px;
    width: 100%;
  }
  .option_financement {
    padding-top: 0 !important;
  }
  .titre_financement {
    text-align: center;
    padding: 10px;
  }
  .conditions_carte {
    padding: 10px;
    text-align: center;
    margin-bottom: 5px;
  }
  .carte-norauto {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 575px) {
  #app_impression_simuler, #app_impression_imprimer {
    width: 100%;
  }
  #app_impression_imprimer {
    padding-left: 0;
  }
  .relancer-simulation {
    width: 100%;
    display: block;
    text-align: center;
  }
}

@media screen and (min-width: 512px) and (max-width: 575px) {
  .print-icone::before {
    left: 125px;
  }
}

@media screen and (max-width: 512px) {
  .print-icone::before {
    left: 100px;
  }
}

@media screen and (max-width: 462px) {
  .print-icone::before {
    left: 75px;
  }
}

@media screen and (max-width: 410px) {
  .print-icone::before {
    left: 45px;
  }
}

@media screen and (max-width: 1854px) {
  .container-block {
    width: 90%;
  }
}

@media screen and (max-width: 991px) {
  .payants .onglet, .gratuits .onglet, .facilypay .onglet, .rond .onglet {
    font-size: 15px;
  }
}

@media screen and (max-width: 1200px) {
  .actions_simulation {
    max-height: none;
  }
}

@media screen and (min-width: 860px) {
  #app_init_simuler {
    margin-left: 20px;
  }
  input#app_init_montant {
    width: 80%;
    margin-left: 10px;
  }
}

/*@media screen and (min-width: 1100px) {*/
/*  .container {*/
/*    width: 95%;*/
/*  }*/
/*}*/

@media screen and (max-width: 1000px) {
  input#app_impression_montant {
    margin-bottom: 15px;
    width: 90%;
  }
}

@media screen and (max-width: 1199px) {
  /* .simulation_link {
    width: 30% !important;
  } */
  .bloc_choix_simulation {
    margin-top: 0px;
  }
}

@media screen and (max-width: 991px) {
  .simulation_link {
    /* width: 100% !important; */
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 577px) and (max-width: 767px) {
  .simulation_link {
    width: 50% !important;
  }
  .impression_btn {
    width: 50% !important;
  }
}

.promo-img{
  position: absolute;
  left: -4px;
  width: 57px;
}

.encadres_simulation .onglet-row1 {
  width: 100%!important;
}
.encadres_simulation .onglet-row2 {
  width: 50%!important;
}
.encadres_simulation .onglet-row3 {
  width: 33.3%!important;
}
.encadres_simulation.rond .onglet {
  display: none!important;
}
.encadres_simulation.rond .onglet.active {
  display: block!important;
  width: 100%!important;
}
.encadres_simulation .rond {
  width: 100%!important;
}
.encadres_simulation.rond .content {
  margin-top: 0!important;
}
.encadres_simulation .content label {
  max-width: 85%;
}

/**
*sliders
**/

#sliders-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ligne-mensualites,
.ligne-duree {
  height: 90px;
  margin-top: 20px;
}

.ligne-mensualites label,
.ligne-duree label {
  display: block;
  text-align: center;
  color: #3b3b3b;
  font-size: 17px;
  font-weight: bold;
}
.ligne-mensualites label span,
.ligne-duree label span {
  font-weight: normal;
}

.ligne-duree label {
  font-size: 14px;
}

.ligne-mensualites label span,
.ligne-duree label span {
  font-weight: normal;
}

.ligne-mensualites .content_slid {
  height: 8px;
}

.ligne-mensualites {
  padding-left: 5%;
}

.ligne-duree {
  padding-left: 11%;
}
.ligne-duree .content_slid {
  height: 4px;
  width: 60%;
}

/****** surcouche jquery ui *****/

.ui-slider-handle {
  height: 45px;
  margin-left: -47px;
  width: 95px;
  padding-top: 18px;
  position: absolute;
  z-index: 600;
  cursor: pointer;
}

.infos {
  background: url("../images/curseur-yellow.png") no-repeat scroll center top transparent;
  color: #000;
  font-weight: bold;
  line-height: 18px;
  padding-left: 0;
  padding-top: 18px;
  padding-bottom: 18px;
  position: absolute;
  text-align: center;
  top: 2px;
  width: 95px;
  z-index: 500;
  font-size: 18px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.infos-active {
  background-position: center -98px;
  color: #fff;
}

.infos span {
  display: block;
  font-weight: normal;
  font-size: 13px;
  line-height: 7px;
}

.ligne-duree .ui-slider-handle {
  margin-left: -37px;
}
.ligne-duree .ui-slider-handle,
.ligne-duree .infos {
  width: 74px;
  top: 0px;
}

.ligne-duree .infos {
  background-image: url("../images/curseur-petit-yellow.png");
  padding-top: 11px;
  padding-bottom: 11px;
  font-size: 16px;
  line-height: 16px;
}
.ligne-duree .infos span {
  font-size: 12px;
}
.ligne-duree .infos-active {
  background-position: center -77px;
}
:focus {
  outline: 0;
}

.content_slid {
  position: relative;
  width: 76%;
  background: #fcb731;
  height: 6px;
  position: relative;
  display: inline-block;
  border-radius: 5px;
}

.echelle0,
.echelle1,
.echelle2 {
  font-weight: bold;
  display: block;
  font-size: 14px;
  position: relative;
}

.echelle1 {
  text-align: center;
}

.echelle0,
.echelle2 {
  display: inline-block;
  width: 7%;
}

.echelle0 {
  padding-right: 3%;
  text-align: right;
  width: 8.5%;
}
.echelle2 {
  padding-left: 3%;
}
